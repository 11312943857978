import { defineNuxtPlugin } from "#app"
import axios from "@evercam/shared/api/client/axios"
import { AxiosError } from "axios"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import { OnRequestExpiredTokenInterceptor } from "@evercam/shared/api/client/interceptors"

export default defineNuxtPlugin((nuxtApp) => {
  const { $auth, $errorTracker } = nuxtApp.nuxt2Context
  const {
    apiURL,
    aiApiUrl,
    ingestApiUrl,
    stagingAiApiUrl,
    posthogApiUrl,
    posthogProjectId,
    posthogPrivateApiKey,
    firebaseVideowallUrl,
    weatherApiBaseUrl,
    evercamLabsUrl,
    snapshotsURL,
    firebaseDbLink,
  } = nuxtApp.$config.public

  axios.addEnvironmentVariables({
    baseUrl: apiURL,
    aiApiUrl,
    ingestApiUrl,
    stagingAiApiUrl,
    posthogApiUrl,
    posthogProjectId,
    posthogPrivateApiKey,
    firebaseVideowallUrl,
    weatherApiBaseUrl,
    evercamLabsUrl,
    snapshotsURL,
    firebaseDbLink,
    getAuthToken: () => $auth.getToken(),
    errorLogger: $errorTracker.leaveBreadcrumb,
    isStaging: window.location.hostname.includes("staging"),
  })

  const onRequest = (request) =>
    OnRequestExpiredTokenInterceptor(
      request,
      () => $auth?.hasToken() && $auth?.isTokenExpired(),
      $auth?.logout
    )

  const onError = async (error) => {
    const isCancelled = axios.isCancel(error as AxiosError)
    const url = error.config?.url
    const statusCode = error.response ? error.response.status : null
    const is401Error = url && !isCancelled && statusCode === 401

    if (!is401Error) {
      return Promise.reject(error)
    }

    await useAccountStore().handle401Error(error)

    return Promise.reject(error)
  }

  axios.addRequestInterceptor(onRequest, onError)
})
