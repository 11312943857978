import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import axios from "@evercam/shared/api/client/axios"
import moment from "moment-timezone"
import { AxiosError } from "axios"
import { BimModelType, DateTime } from "@evercam/shared/types"
import { findNearestTimelineIndex } from "@evercam/shared/utils"
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { useBimTransparencyStore } from "@/stores/bimTransparency"
import { useNuxtApp } from "#app"

interface BimCompareState {
  availableDays: string[] | null
  availableHours: number[] | null
  bimAvailableDays: string[] | null
  bimAvailableDaysLabels: string[] | null
  isBimAvailable: boolean
  bimDailyEvents: string[] | null
  bimDatetime: DateTime | null
  bimIndexMax: number
  bimIndex: number
  bimMaxDate: DateTime | null
  bimMinDate: DateTime | null
  isSnapshotLoading: boolean
  currentBimMaskId: number | null
  currentLayerId: number | null
  disableLatest: boolean
  disableOldest: boolean
  finalView: boolean
  fullDates: string[] | null
  has4D: boolean
  isBottombarPreview: boolean
  isWidgetPreview: boolean
  isSidebarPreview: boolean
  latestBimImage: string | null
  model: string | null
  models: string[] | null
  selectedHour: DateTime | null
  transparency: number
  isBimImageLoading: boolean
  snapshotCurrentDate: DateTime
}

export const useBimCompareStore = defineStore({
  id: "bimCompare",
  state: (): BimCompareState => ({
    availableDays: [],
    availableHours: [],
    bimAvailableDays: [],
    bimAvailableDaysLabels: [],
    isBimAvailable: true,
    bimDailyEvents: [],
    bimDatetime: null,
    bimIndexMax: 0,
    bimIndex: 0,
    bimMaxDate: null,
    bimMinDate: null,
    isSnapshotLoading: false,
    currentBimMaskId: null,
    currentLayerId: null,
    disableLatest: false,
    disableOldest: false,
    finalView: true,
    fullDates: [],
    has4D: false,
    isBottombarPreview: false,
    isWidgetPreview: false,
    isSidebarPreview: true,
    latestBimImage: null,
    model: null,
    models: null,
    selectedHour: null,
    transparency: 100,
    isBimImageLoading: false,
    snapshotCurrentDate: moment().toISOString(),
  }),
  actions: {
    async fetchBimData({ cameraExid }) {
      try {
        // Add cancel token for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)

        this.isBimImageLoading = true
        this.snapshotCurrentDate = moment(this.snapshotCurrentDate)
          .add(1, "hours")
          .toISOString()

        const bimData = await EvercamApi.bim.getBimModel(
          cameraExid,
          {
            model: this.model,
            timestamp: this.snapshotCurrentDate,
          },
          { cancelToken: cancelToken.token }
        )
        if (!bimData) {
          this.isBimAvailable = false

          return
        }

        return bimData
      } catch (error) {
        console.log(error)
      }
    },
    async fetchBimImage({ cameraExid, timestamp }) {
      this.isBimImageLoading = true

      let isCancelled = false
      try {
        // Add cancel tokens for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)

        const payload = {
          model: this.model,
          layerTimestamp: this.snapshotCurrentDate,
        }

        const { imageUrl } = await EvercamApi.bim.getBimSnapshot(
          cameraExid,
          timestamp,
          payload,
          { cancelToken: cancelToken.token }
        )

        this.latestBimImage = imageUrl
      } catch (error) {
        isCancelled = axios.isCancel(error as AxiosError)

        if (!isCancelled) {
          useNuxtApp().nuxt2Context.$notifications.warn(
            useNuxtApp().vue2App.$i18n.t("content.bim.no_bim_image") as string
          )
        }
      }
    },
    async fetchBimMaskLayers({ cameraExid }) {
      const bimTransparencyStore = useBimTransparencyStore()
      try {
        // Add cancel token for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)
        const { id, startAt, shapes } = await EvercamApi.layers.getLayer(
          cameraExid,
          {
            timestamp: this.snapshotCurrentDate,
            layerType: LAYER_TYPES.BIM_MASK,
          },
          { cancelToken: cancelToken.token }
        )
        if (
          !shapes ||
          moment(this.snapshotCurrentDate).isBefore(moment(startAt))
        ) {
          bimTransparencyStore.shapes = []

          return
        }
        this.currentBimMaskId = id
        const shapesParsed = JSON.parse(shapes)
        bimTransparencyStore.shapes = shapesParsed
      } catch (error) {
        if (
          (error as AxiosError).response &&
          (error as AxiosError).response?.status !== 404
        ) {
          useNuxtApp().nuxt2Context.$errorTracker?.notify(error)
        } else {
          bimTransparencyStore.shapes = []
        }
      }
    },
    async updateBimProperties(bimData) {
      const {
        model: bimModel,
        models: bimModels,
        dates: bimDates,
      } = bimData || {}
      this.models = bimModels
      this.model = bimModel
      this.has4D = this.models.includes(BimModelType["FourD"])
      this.finalView = !bimDates?.length
      if (this.finalView) {
        this.resetBimProperties(bimData)

        return
      }

      const lastBimAvailableDaysIndex = bimDates?.length - 1
      this.updateBimDateProperties(bimData)
      const nearestTimelineIndex = findNearestTimelineIndex(
        this.snapshotCurrentDate,
        this.bimAvailableDays
      ) as number
      const nearestTimelineDate = bimDates[nearestTimelineIndex]

      this.latestBimImage = ""

      const bimMaxDate = bimDates?.[lastBimAvailableDaysIndex]
      this.bimMaxDate = moment(bimMaxDate).isValid()
        ? moment(bimMaxDate).format("YYYY-MM-DD")
        : ""
      const bimMinDate = bimDates?.[0]
      this.bimMinDate = moment(bimMinDate).isValid()
        ? moment(bimMinDate).format("YYYY-MM-DD")
        : ""
      this.bimDatetime = moment(nearestTimelineDate).format("YYYY-MM-DD")
      this.selectedHour = moment(nearestTimelineDate).format("HH:mm")
      this.bimIndexMax = lastBimAvailableDaysIndex
      this.bimIndex = nearestTimelineIndex
      this.fullDates = bimDates
    },
    updateBimDateProperties(bimInfo) {
      const { dates } = bimInfo || {}
      const lastBimAvailableDaysIndex = dates?.length - 1

      const data = dates?.reduce(
        (acc, date, index) => {
          const day = moment(date).format("DD-MM-YYYY HH:mm")
          const dayLabel = [0, lastBimAvailableDaysIndex].includes(index)
            ? moment(date).format("DD-MM-YYYY")
            : ""
          const dailyEventDate = moment(date).format("YYYY-MM-DD")

          return {
            availableDays: [...acc.availableDays, day],
            availableDaysLabels: [...acc.availableDaysLabels, dayLabel],
            dailyEvents: [...acc.dailyEvents, dailyEventDate],
          }
        },
        {
          availableDays: [],
          availableDaysLabels: [],
          dailyEvents: [],
        }
      )

      this.bimAvailableDays = data.availableDays
      this.bimAvailableDaysLabels = data.availableDaysLabels
      this.bimDailyEvents = data.dailyEvents
    },
    resetBimProperties(bimInfo) {
      this.bimMinDate = null
      this.bimMaxDate = null
      this.bimDatetime = undefined
      this.selectedHour = null
      this.latestBimImage = bimInfo?.imageUrl
      this.bimAvailableDays = []
      this.bimDailyEvents = []
      this.bimIndexMax = 0
      this.bimIndex = 0
      this.bimAvailableDaysLabels = []
      this.fullDates = []
    },
  },
})
