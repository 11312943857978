import { defineStore } from "pinia"
import {
  EvercamApiError,
  EvercamApiErrorCode,
  UnitSystem,
  User,
  UserFeatureFlag,
} from "@evercam/shared/types"
import md5 from "md5"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { camelizeKeys } from "humps"
import { useLayoutStore } from "@evercam/dashboard/stores/layout"
import { useProjectStore } from "@evercam/dashboard/stores/project"
import { useCameraStore } from "@evercam/dashboard/stores/camera"
import axios from "@evercam/shared/api/client/axios"
import { AxiosError } from "axios"
import { useConnectorStore } from "@evercam/dashboard/stores/connector"
import { useNuxtApp, useRuntimeConfig } from "#app"

const isIframe = parent !== window
let statesKey = ""

if (isIframe) {
  statesKey = window.location.pathname.includes("widgets")
    ? "dashboardWidgetStates"
    : "dashboardDemoStates"
} else {
  statesKey = "dashboardAccountStates"
}

type ZohoLogin = { redirectToZoho: boolean }
type ApiLogin = { apiId: string; apiKey: string }
type ImpersonationLogin = { impersonationToken: string }
type UsernamePasswordLogin = { username: string; password: string }

type LoginForm = UsernamePasswordLogin | ApiLogin | ImpersonationLogin | {}

export type AccountState = {
  apiId: string
  apiKey: string
  companyExid: string
  companyId: string
  companyName: string
  country: string
  email: string
  evercamAccount: string
  firstname: string
  idpHint: string
  isAdmin: boolean
  isApiLogin: boolean
  isBimCompareWidget: boolean
  isDemo: boolean
  isLiveViewWidget: boolean
  isUsingKeycloak: boolean
  isRecordingWidget: boolean
  isWidget: boolean
  lastname: string
  persona: string
  preventAccountInformationDialog: boolean
  redirectUrl: string
  telephone: string
  token: string
  unitSystem: UnitSystem
  features: string[]
}

//@ts-ignore
export const useAccountStore = defineStore("account", {
  state: (): AccountState => ({
    apiId: null,
    apiKey: null,
    companyExid: null,
    companyId: null,
    companyName: null,
    country: null,
    email: null,
    evercamAccount: "",
    firstname: null,
    idpHint: null,
    isAdmin: null,
    isApiLogin: false,
    isBimCompareWidget: false,
    isDemo: false,
    isLiveViewWidget: false,
    isUsingKeycloak: false,
    isRecordingWidget: false,
    isWidget: false,
    lastname: null,
    persona: null,
    preventAccountInformationDialog: false,
    telephone: null,
    redirectUrl: null,
    token: null,
    unitSystem: UnitSystem.Metric,
    features: [],
  }),
  actions: {
    async updateFeaturesAccess() {
      try {
        const { features } = await EvercamApi.users.fetchAccessFeatures(
          this.email
        )
        this.features = features
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.account.fetch_access_features_error"
          ),
          error,
        })
      }
    },
    hasUserAccessToFeature(feature: UserFeatureFlag) {
      return this.features?.includes(feature)
    },
    updateUser(user) {
      this.firstname = user?.firstname
      this.lastname = user?.lastname
      this.email = user?.email
      this.telephone = user?.telephone
      this.country = user?.country
      this.isAdmin = user?.isAdmin
      this.persona = user?.persona
      this.companyId = user?.companyId
      this.companyName = user?.companyName
      this.companyExid = user?.companyExid
      this.features = user?.features

      useNuxtApp().nuxt2Context.$errorTracker.setUser({
        email: user?.email,
        username: `${user?.firstname} ${user?.lastname}`,
      })
    },
    updateApiCredentials({ apiId, apiKey }) {
      this.apiId = apiId
      this.apiKey = apiKey
    },
    async logout({
      redirect = true,
      revoke = true,
      cleanState = true,
      persistCurrentUrl = true,
    } = {}) {
      const isTokenExpired = useNuxtApp().nuxt2Context.$auth.isTokenExpired()
      if (revoke && !isTokenExpired) {
        EvercamApi.users
          .logout(
            { token: this.token },
            { headers: { Authorization: `Bearer ${this.token}` } }
          )
          .catch((e) => {
            if (e instanceof AxiosError && e.response?.status !== 401) {
              useNuxtApp().nuxt2Context.$errorTracker?.save(e)
            }
          })
      }
      axios.setToken(null)
      useNuxtApp().nuxt2Context?.$posthog?.reset()

      this.isUsingKeycloak = false

      if (cleanState) {
        this.$reset()
        useCameraStore().$reset()
        useProjectStore().$reset()
      }

      this.updateApiCredentials({
        apiId: null,
        apiKey: null,
      })

      if (!redirect) {
        return
      }

      if (persistCurrentUrl) {
        this.redirectUrl = useNuxtApp().vue2App.$route?.fullPath
      }

      useNuxtApp().nuxt2Context.app.router.push("/v2/users/signin")
    },
    async login(
      { form, redirect = true }: { form?: LoginForm; redirect: boolean } = {
        redirect: true,
      }
    ) {
      const layoutStore = useLayoutStore()
      layoutStore.loader = true

      this.cleanUrlCredentials()

      const { route } = useNuxtApp().nuxt2Context,
        authCode = route.query.code,
        ssoProvider = window.localStorage?.getItem("ssoProvider"),
        { apiId, apiKey } = (form || {}) as ApiLogin,
        { impersonationToken } = (form || {}) as ImpersonationLogin
      let response

      try {
        if (impersonationToken) {
          response = await EvercamApi.users.impersonationLogin(
            impersonationToken
          )
        } else if (authCode) {
          response = await EvercamApi.users.loginWithProvider(ssoProvider, {
            code: authCode as string,
            redirectUri: `${window.location.origin}/auth/${ssoProvider}`,
          })
        } else if (this.isApiLogin && apiId && apiKey) {
          response = await EvercamApi.users.loginWithApiCredentials(
            apiId,
            apiKey
          )
        } else {
          response = await EvercamApi.users.login(form as UsernamePasswordLogin)
        }
        const { token, evercamAccount, ...user } = response
        this.updateUser(user)
        this.evercamAccount = evercamAccount
        this.token = token
        axios.setToken(token)
        if (!apiId || !apiKey) {
          EvercamApi.users
            .getCredentials()
            .then((apiCredentials) => this.updateApiCredentials(apiCredentials))
        }

        if ((form as ZohoLogin)?.redirectToZoho) {
          this.zohoLogin(user)
        } else if (redirect) {
          this.redirect()
        }
        useNuxtApp().nuxt2Context.$posthog.identify()
      } catch (e) {
        const error = e as EvercamApiError
        if (error.response?.data?.code === "INVALID_CREDENTIALS") {
          useNuxtApp().nuxt2Context.$notifications.warn(
            error.response?.data?.message
          )

          return
        }
        if (error?.response?.data?.code === EvercamApiErrorCode.PasswordReset) {
          useNuxtApp().nuxt2Context.app.router.push(
            `/v2/users/password-reset?email=${
              (form as UsernamePasswordLogin)?.username
            }`
          )
        }
        layoutStore.loader = false

        const sourceUrlRequestedSso = window.localStorage?.getItem(
          "sourceUrlRequestedSso"
        )
        const errorMessage =
          sourceUrlRequestedSso === "/v2/users/signup"
            ? "signup_failed"
            : "login_failed"
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            `content.auth.${errorMessage}`
          ) as string,
          error,
          showDetails: ssoProvider
            ? error?.response?.data?.code ===
              EvercamApiErrorCode.RequireShareRequest
            : true,
        })

        if (ssoProvider) {
          return new Promise((_, reject) => reject())
        }
      } finally {
        layoutStore.loader = false
        if (ssoProvider) {
          window.localStorage?.removeItem("ssoState")
          window.localStorage?.removeItem("ssoProvider")
        }
      }
    },
    async apiLogin({ apiId, apiKey }) {
      this.cleanUrlCredentials()
      if (this.token) {
        await this.logout({ redirect: false })
      }
      this.updateApiCredentials({ apiId, apiKey })
      this.isApiLogin = true
      await this.login({
        form: {
          apiId,
          apiKey,
        },
        redirect: false,
      })

      useNuxtApp().nuxt2Context.$errorTracker?.leaveBreadcrumb({
        category: "info",
        message: `Remote login. With token? : ${!!this.token}`,
      })
    },
    async impersonationLogin() {
      const { route } = useNuxtApp().nuxt2Context
      const { impersonationToken } = camelizeKeys(route.query) as {
        impersonationToken: string
      }
      this.cleanUrlCredentials()
      if (this.token) {
        await this.logout({ redirect: false })
      }
      await this.login({ form: { impersonationToken }, redirect: false })
    },
    zohoLogin({ email, lastname, firstname }) {
      const timestamp = useNuxtApp()
        .nuxt2Context.$moment.utc()
        .valueOf() as unknown as string
      const remoteAuthKey = `${useRuntimeConfig().remoteAuthKey}`,
        operation = "signup",
        utype = "portal",
        fullName = `${firstname} ${lastname}`,
        loginName = fullName.toLowerCase().replace(/ /g, "."),
        apikey = md5(
          `${operation}${email}${loginName}${fullName}${utype}${remoteAuthKey}${timestamp}`
        )
      const redirectURL = `https://support.evercam.io/support/RemoteAuth?operation=${encodeURI(
        operation
      )}&email=${encodeURI(email)}&fullname=${encodeURI(
        fullName
      )}&loginname=${encodeURI(loginName)}&utype=${encodeURI(
        utype
      )}&ts=${encodeURI(timestamp)}&apikey=${apikey}`
      window.location.href = redirectURL
    },
    zohoLogout() {
      this.$reset()
      useCameraStore().$reset()
      useProjectStore().$reset()
      axios.setToken(null)
    },
    async fetchUserData() {
      try {
        if (!this.token) {
          return
        }
        const jwt = JSON.parse(atob(this.token.split(".")[1]))
        const response = await EvercamApi.users.getUser(jwt.email)
        const userData = response?.users[0]

        this.updateUser(userData)
        this.evercamAccount = userData?.evercamAccount
      } catch (e) {
        const error = e as EvercamApiError
        if (error.response?.status !== 401) {
          useNuxtApp().nuxt2Context.$errorTracker?.save(error)
        }
      }
    },
    async fetchUserCredentials() {
      const credentials = await EvercamApi.users.getCredentials()
      this.updateApiCredentials(credentials)
    },
    cleanUrlCredentials() {
      const { route } = useNuxtApp().nuxt2Context
      route.query.api_id = null
      route.query.api_key = null
      route.query.authorization = null
      route.query.impersonation_token = null
      const query = document.location.search.replace(
        /((&)?api_id=[a-zA-Z0-9]+|(&)?api_key=[a-zA-Z0-9]+|(&)?authorization=[\w-]+\.[\w-]+\.[\w-]+|(&)?impersonation_token=[a-z0-9-]+)/g,
        ""
      )
      window.history.replaceState(
        {},
        document.title,
        `${document.location.pathname}${query.replace(/\?$/g, "")}`
      )
    },
    redirect() {
      const redirectUrl = this.redirectUrl
      this.redirectUrl = null
      if (
        redirectUrl &&
        !redirectUrl.includes("v2/users/signin") &&
        !redirectUrl.includes("v2/users/signout")
      ) {
        window.location.href = redirectUrl
      } else {
        window.location.href = "/v2/projects"
      }
    },
    async handle401Error(error: AxiosError) {
      const url = error.config?.url

      const connectorStore = useConnectorStore()
      if (connectorStore.isConnectionError(error)) {
        return connectorStore.handle401Error(error)
      }

      const shouldLogout = !this.isWidget && url.includes(axios.env.baseUrl)
      if (shouldLogout) {
        await this.logout({ revoke: false })
      }
    },
  },
  getters: {
    fullname: (state): string => `${state.firstname} ${state.lastname}`,
    isUserOffline: (state): boolean => !state.email,
    tokenMetaData(): { impersonated_by?: string } {
      if (this.token) {
        const tokenData = this.token.split(".")[1]

        return JSON.parse(atob(tokenData))
      }

      return {}
    },
    isImpersonationLogin(): boolean {
      return !!this.tokenMetaData.impersonated_by
    },
    impersonatorEmail(): string {
      return this.tokenMetaData.impersonated_by
    },
    authorized(): boolean {
      return (
        this.token &&
        this.email &&
        !useNuxtApp().nuxt2Context?.$auth?.isTokenExpired()
      )
    },
    userObject(): User {
      return {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        telephone: this.telephone,
        country: this.country,
        isAdmin: this.isAdmin,
        persona: this.persona,
        companyId: this.companyId,
        companyName: this.companyName,
        companyExid: this.companyExid,
        features: this.features,
      }
    },
  },
  persist: {
    key: statesKey,
  },
})
