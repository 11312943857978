import { defineStore } from "pinia"
import { AiApi } from "@evercam/shared/api/aiApi"
import {
  GateReportROI,
  GateReportEvent,
  DailyEventCount,
  HourlyEventCount,
} from "@evercam/shared/types/gateReport"
import moment from "moment-timezone"
import { EventMarker } from "@evercam/shared/types"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"

interface EventsState {
  events: GateReportEvent[]
  eventsTypes: []
  filteredEvents: EventMarker<GateReportEvent>[] | []
  activeRoi: GateReportROI
  selectedEvent: EventMarker<GateReportEvent>
  eventsDailyCounts: DailyEventCount[]
  eventsHourlyCounts: HourlyEventCount[]
}

export const useEventsStore = defineStore({
  id: "events",
  state: (): EventsState => ({
    events: [],
    eventsTypes: [],
    filteredEvents: [],
    activeRoi: {} as GateReportROI,
    selectedEvent: {} as EventMarker<GateReportEvent>,
    eventsDailyCounts: [],
    eventsHourlyCounts: [],
  }),
  actions: {
    async fetchEventsCounts() {
      try {
        const projectExid = useProjectStore().selectedProjectExid
        const cameraExid = useCameraStore().selectedCameraExid
        const data = await AiApi.gateReport.getEventCounts(projectExid, {
          camerasExid: [cameraExid],
          isPublic: true,
        })
        this.eventsDailyCounts = data?.days
      } catch (err) {
        console.log(err)
      }
    },
    async fetchEventsByDate(params) {
      try {
        const projectExid = useProjectStore().selectedProjectExid
        const cameraExid = useCameraStore().selectedCameraExid
        const { items } = await AiApi.gateReport.getAllEvents(projectExid, {
          camerasExid: [cameraExid],
          limit: 2000,
          isPublic: true,
          ...params,
        })
        this.events = items
        this.updateHourlyEventsCounts(items)
      } catch (err) {
        console.log(err)
      }
    },
    updateHourlyEventsCounts(events) {
      const hourlyCounts = events.reduce((acc, event) => {
        const timezone = useCameraStore().selectedCameraTimezone
        const eventHour = event.eventTime
          ? moment.tz(event.eventTime, timezone).format("HH")
          : ""

        if (eventHour) {
          acc = {
            ...acc,
            [eventHour]: {
              hour: eventHour,
              count: (acc[eventHour]?.count || 0) + 1,
            },
          }
        }

        return acc
      }, {})
      this.eventsHourlyCounts = Object.values(hourlyCounts)
    },
  },
  getters: {
    activeEvent: (state) => {
      return () =>
        state.filteredEvents.find(
          (e: EventMarker<GateReportEvent>) => e.isActive
        ) || null
    },
  },
})
