import { Socket } from "phoenix-socket"
import { useAccountStore } from "@/stores/account"
import { defineNuxtPlugin } from "#app"

const websocketPlugin = (app) => {
  interface ActiveSocket {
    url: string
    socket: Socket
    options?: object
  }

  type Channels = Record<string, any>

  const WebSocket = () => {
    let activeSockets: ActiveSocket[] = []
    let channels: Channels = {}

    const findActiveSocket = (url: string): Socket | undefined => {
      const activeSocket = activeSockets.find((socket) => socket.url === url)

      return activeSocket?.socket
    }

    const connect = (url: string): Socket => {
      const existingSocket = findActiveSocket(url)
      if (existingSocket && existingSocket.isConnected()) {
        return existingSocket
      }
      const options = {
        params: {
          ip: "1.1.1.1",
          source: "dashboard",
          token: useAccountStore().token || "",
        },
      }
      const socket = new Socket(url, options)
      socket.connect()

      socket.onError((error: any) => {
        console.error("Socket error:", error)
      })

      socket.onClose(() => {
        activeSockets = activeSockets.filter((socket) => socket.url !== url)
      })

      activeSockets.push({ url, socket })

      return socket
    }

    const channel = <T>(url: string, topic: string): T => {
      const socket = findActiveSocket(url) || connect(url)
      if (!channels[topic]) {
        channels[topic] = socket.channel(topic)
        channels[topic].join()
      }

      return channels[topic]
    }

    const disconnect = () => {
      activeSockets.forEach(({ socket }) => {
        socket.disconnect()
      })
      activeSockets = []
      channels = {}
    }

    return {
      channel,
      disconnect,
    }
  }
  const websocket = WebSocket()
  app.provide("websocket", websocket)
}

export default defineNuxtPlugin(websocketPlugin)
