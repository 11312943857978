import { makeFullScreen, exitFullScreen } from "@evercam/shared/utils"
import { useLayoutStore } from "@/stores/layout"
import { defineNuxtPlugin } from "#app"
export default defineNuxtPlugin((app) => {
  const layoutStore = useLayoutStore()
  const toggle = (fullscreenElement) => {
    if (layoutStore.isFullscreen) {
      exitFullScreen()
    } else {
      makeFullScreen(fullscreenElement || layoutStore.fullscreenElement)
    }
  }

  const exit = () => {
    exitFullScreen()
  }

  const setFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement
    ) {
      layoutStore.isFullscreen = true
    } else {
      layoutStore.isFullscreen = false
    }
  }

  window.addEventListener("fullscreenchange", setFullscreen)
  window.addEventListener("webkitfullscreenchange", setFullscreen)
  window.addEventListener("mozfullscreenchange", setFullscreen)
  window.addEventListener("MSFullscreenChange", setFullscreen)

  app.provide("fullscreen", {
    toggle,
    exit,
  })
})
