import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import moment from "moment-timezone"
import { ExNvrApi } from "@evercam/shared/api/exNvrApi"
import { useSnapshotStore } from "@/stores/snapshots"
import axios from "@evercam/shared/api/client/axios"
import {
  AnalyticsEvent,
  CameraExid,
  Date_YYYY_MM,
  Date_YYYY_MM_DD,
  DateTime_tz,
  DateTime_Z,
  ExNvrRecordingInterval,
} from "@evercam/shared/types"
import { Canceler as AxiosCancelFn, AxiosError } from "axios"
import { useCameraStore } from "@/stores/camera"
import { captureVideoFrameToBase64, debounce } from "@evercam/shared/utils"
import { useNuxtApp } from "#app"
import { useAccountStore } from "./account"

export enum RecordingsTabs {
  Recordings = 0,
  XRay = 1,
  BrainTool = 2,
}

export type ImageDimensions = {
  width: number
  height: number
  naturalWidth: number
  naturalHeight: number
}

interface RecordingsState {
  availableDaysByMonth: Record<Date_YYYY_MM, number[]>
  availableHoursByDay: Record<Date_YYYY_MM_DD, number[]>
  selectedTimestamp: DateTime_tz | null
  isXrayActive: boolean
  isSnapshotPlayerLoading: boolean
  isItwinActive: boolean
  isXrayLoading: boolean
  isBrainToolActive: boolean
  isEditing: boolean
  isDownloading: boolean
  isLive: boolean
  isVideo: boolean
  isPlaying: boolean
  isEdgeVideo: boolean
  isEdgeStreamingReady: boolean
  isVideoLoading: boolean
  selectedXrayTimestamp: DateTime_tz | null
  selectedXraySnapshot: string
  snapshotImgElement: HTMLImageElement
  videoElement: HTMLVideoElement
  videoPosterUrl: string
  filtersPanel: boolean
  xraySnapshotRequestCancelFn: AxiosCancelFn
  edgeStreamingToken: string
  edgeStreamingTokenDate: string | null
  exNvrAvailableRecordingsIntervals: ExNvrRecordingInterval[]
  exNvrMinRecordingDate: DateTime_Z
  exNvrMaxRecordingDate: DateTime_Z
  exportedXrayImage: string | null
  selectedTab: RecordingsTabs
  isAvailableRecordingsFetching: boolean
  isExNvrError: boolean
  isSnapshotAvailable: boolean
  isInitialized: boolean
  currentSnapshot: {
    createdAt: string
    data: string
  }
}

export const useRecordingsStore = defineStore("recordings", {
  state: (): RecordingsState => ({
    availableDaysByMonth: {},
    availableHoursByDay: {},
    selectedTimestamp: null,
    isXrayActive: false,
    isSnapshotPlayerLoading: false,
    isItwinActive: false,
    isXrayLoading: false,
    isBrainToolActive: false,
    isEditing: false,
    isDownloading: false,
    isLive: false,
    isPlaying: false,
    isVideo: false,
    isEdgeVideo: false,
    isEdgeStreamingReady: false,
    isVideoLoading: false,
    selectedXrayTimestamp: "",
    selectedXraySnapshot: "",
    snapshotImgElement: {
      src: "",
      height: null,
      width: null,
    } as HTMLImageElement,
    videoElement: {} as HTMLVideoElement,
    videoPosterUrl: "",
    filtersPanel: false,
    xraySnapshotRequestCancelFn: null,
    edgeStreamingToken: "",
    edgeStreamingTokenDate: null,
    exNvrAvailableRecordingsIntervals: [],
    exNvrMinRecordingDate: new Date().toISOString(),
    exNvrMaxRecordingDate: new Date().toISOString(),
    exportedXrayImage: null,
    selectedTab: RecordingsTabs.Recordings,
    isAvailableRecordingsFetching: false,
    isExNvrError: false,
    isSnapshotAvailable: true,
    isInitialized: false,
    currentSnapshot: null,
  }),
  getters: {
    edgeStreamingConfig(): Record<string, string> {
      const camera = useCameraStore().selectedCamera
      const apiUrl = `https://${camera?.nvrHost}:${camera?.nvrHttpPort}`

      return {
        apiUrl,
        deviceId: camera?.nvrDeviceId,
        streamingUrl: `${apiUrl}${camera?.nvrStreamingEndpoint}`,
        snapshotUrl: `${apiUrl}${camera?.nvrSnapshotEndpoint}`,
        bifUrl: `${apiUrl}/api/devices/${camera?.nvrDeviceId}/bif`,
      }
    },
    hasGateReport(): boolean {
      return (
        useNuxtApp().nuxt2Context.$permissions.camera.has.gateReport() &&
        useNuxtApp().nuxt2Context.$permissions.user.can.access.gateReport()
      )
    },
    getCurrentImageSrc: (state) => (): string => {
      if (!state.isEdgeVideo) {
        return state.snapshotImgElement?.src
      }
      if (state.videoElement && !state.isVideoLoading) {
        return captureVideoFrameToBase64(state.videoElement)
      }

      return state.videoPosterUrl
    },
    getCurrentImageDimensions: (state) => (): ImageDimensions => {
      if (state.isEdgeVideo && state.videoElement) {
        return {
          width: state.videoElement.offsetWidth,
          height: state.videoElement.offsetHeight,
          naturalWidth: state.videoElement.videoWidth,
          naturalHeight: state.videoElement.videoHeight,
        }
      } else {
        return {
          width: state.snapshotImgElement?.offsetWidth,
          height: state.snapshotImgElement?.offsetHeight,
          naturalWidth: state.snapshotImgElement?.naturalWidth,
          naturalHeight: state.snapshotImgElement?.naturalHeight,
        }
      }
    },
  },
  actions: {
    reset() {
      const snapshotsStore = useSnapshotStore()
      this.availableDaysByMonth = {}
      this.availableHoursByDay = {}
      this.selectedTimestamp = snapshotsStore.latestSnapshot
        ?.createdAt as string
      this.changeXrayVisibility(false)
      this.isItwinActive = false
      this.isBrainToolActive = false
      this.isEditing = false
      this.isDownloading = false
      this.selectedXrayTimestamp = null
      this.selectedXraySnapshot = null
      this.snapshotImgElement.src = null
      this.snapshotImgElement.height = null
      this.snapshotImgElement.width = null
      this.videoElement = null
      this.videoPosterUrl = ""
      this.filtersPanel = false
      this.edgeStreamingToken = ""
      this.exNvrAvailableRecordingsIntervals = []
      this.isPlaying = false
      this.isLive = false
    },
    changeXrayVisibility(visibilityStatus) {
      if (this.isXrayActive === visibilityStatus) {
        return
      }
      this.isXrayActive = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(AnalyticsEvent.Xray, {
        visible: visibilityStatus,
      })
    },
    changeEditToolVisibility(visibilityStatus) {
      if (this.isEditing === visibilityStatus) {
        return
      }
      this.isEditing = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(AnalyticsEvent.EditTool, {
        visible: visibilityStatus,
      })
    },
    onEditSnapshot() {
      this.changeEditToolVisibility(true)
      this.currentSnapshot = {
        data: this.isVideo
          ? captureVideoFrameToBase64(this.videoElement)
          : this.snapshotImgElement.src,
        createdAt: this.selectedTimestamp,
      }
    },
    onTimestampChange(timestamp: string) {
      this.selectedTimestamp = timestamp
    },
    onSnapshotChange(snapshot: { createdAt: string; data: string }) {
      if ((!snapshot.createdAt || !snapshot.data) && !this.isLive) {
        return
      }
      this.selectedTimestamp = snapshot.createdAt
    },
    resetAvailableRecordings() {
      this.availableDaysByMonth = {}
      this.availableHoursByDay = {}
    },
    onImageLoaded(img) {
      this.snapshotImgElement = img
      this.isSnapshotAvailable = img.src && !img.src.includes("unavailable.jpg")
      this.isInitialized = true
    },
    onVideoLoaded(player) {
      this.videoElement = player
      this.isInitialized = true
    },
    onToggleVideoMode(isVideo: boolean, callback?: () => any) {
      this.isEdgeVideo =
        isVideo &&
        useNuxtApp().nuxt2Context.$permissions.camera.has.edgeVideoStreaming()
      this.isVideo = isVideo
      this.isAvailableRecordingsFetching = true
      if (callback) {
        callback()
      }
      // @ts-expect-error
      this.debounceToggleVideoMode(isVideo)
    },
    debounceToggleVideoMode: debounce(async function (isVideo: boolean) {
      if (!this.isXrayActive) {
        this.resetAvailableRecordings()
      }
      if (isVideo) {
        await this.createEdgeStreamingSession({
          cameraExid: useCameraStore().selectedCamera?.id,
          token: useAccountStore().token,
        })
        await this.fetchExNvrAvailableRecordings()
      }
      this.isAvailableRecordingsFetching = false
    }, 100),
    async fetchAvailableDaysForMonth(date: string, cameraExid: string) {
      const m = moment(date)
      const year = m.format("YYYY")
      const month = m.format("MM")
      const formattedMonth = `${year}-${month}`

      if (this.availableDaysByMonth[formattedMonth]) {
        return
      }

      try {
        const { days } = await EvercamApi.recordings.availableDays({
          cameraId: cameraExid,
          year,
          month,
        })
        this.availableDaysByMonth = {
          ...this.availableDaysByMonth,
          [formattedMonth]: days,
        }
      } catch (err) {
        useNuxtApp().nuxt2Context.$errorTracker.save(err)
      }
    },
    async fetchAvailableHoursForDay(date: string, cameraExid: string) {
      const m = moment(date)
      const year = m.format("YYYY")
      const month = m.format("MM")
      const day = m.format("DD")
      const formattedDate = `${year}-${month}-${day}`

      if (this.availableHoursByDay[formattedDate]) {
        return
      }

      try {
        const { hours } = await EvercamApi.recordings.availableHours({
          cameraId: cameraExid,
          year,
          month,
          day,
        })

        if (!hours.length) {
          return
        }

        this.availableHoursByDay = {
          ...this.availableHoursByDay,
          [formattedDate]: hours,
        }
      } catch (err) {
        useNuxtApp().nuxt2Context.$errorTracker.save(err)
      }
    },
    async createEdgeStreamingSession({
      cameraExid,
      token,
    }: {
      cameraExid: CameraExid
      token: string
    }) {
      try {
        this.edgeStreamingToken = await EvercamApi.cameras.getNvrStreamingToken(
          { cameraExid, token }
        )
        this.edgeStreamingTokenDate = `${new Date()}`
        this.isEdgeStreamingReady = true
      } catch (err) {
        this.isExNvrError = true
        console.error(err)
        useNuxtApp().nuxt2Context.$errorTracker.saveAndNotify({
          ERROR: err as Error,
          REQUEST_PAYLOAD: {
            cameraExid: useCameraStore().selectedCameraExid,
          },
          FEATURE: "EdgeVideo",
        })
      }
    },
    async fetchExNvrAvailableRecordings() {
      try {
        const { apiUrl, deviceId } = this.edgeStreamingConfig
        this.exNvrAvailableRecordingsIntervals =
          await ExNvrApi.devices.getAvailableRecordings({
            deviceId,
            apiUrl,
            token: this.edgeStreamingToken,
          })
        this.populateExNvrMinMaxRecordingsDates()
      } catch (err) {
        this.isExNvrError = true
        console.error(err)
      }
    },
    populateExNvrMinMaxRecordingsDates() {
      const recordings = this.exNvrAvailableRecordingsIntervals

      if (!recordings || recordings.length === 0) {
        return null
      }
      const result = recordings.reduce(
        (acc, recording) => {
          const startDate = moment(recording.startDate)
          const endDate = moment(recording.endDate)

          if (startDate.isBefore(acc.minDate)) {
            acc.minDate = startDate
          }

          if (endDate.isAfter(acc.maxDate)) {
            acc.maxDate = endDate
          }

          return acc
        },
        {
          minDate: moment(recordings[0].startDate),
          maxDate: moment(recordings[0].endDate),
        }
      )

      this.exNvrMinRecordingDate = result.minDate.toISOString()
      this.exNvrMaxRecordingDate = result.maxDate.toISOString()
    },
    populateAvailableExNvrDaysForMonth(month: string, timezone: string) {
      const momentTz = useNuxtApp().nuxt2Context.$moment.tz
      const availableDaysByMonth = { ...this.availableDaysByMonth }
      const selectedMonth = useNuxtApp().nuxt2Context.$moment(month)

      this.exNvrAvailableRecordingsIntervals.forEach((interval) => {
        const intervalStart = momentTz(interval.startDate, timezone)
        const intervalEnd = momentTz(interval.endDate, timezone)

        if (
          intervalEnd.isBefore(selectedMonth.startOf("month")) ||
          intervalStart.isAfter(selectedMonth.endOf("month"))
        ) {
          return
        }

        const startDay = Math.max(
          intervalStart.isSameOrAfter(selectedMonth, "month")
            ? intervalStart.date()
            : 1,
          1
        )

        const endDay = Math.min(
          intervalEnd.isSameOrBefore(selectedMonth.endOf("month"), "date")
            ? intervalEnd.date()
            : selectedMonth.daysInMonth(),
          selectedMonth.daysInMonth()
        )

        const monthKey = selectedMonth.format("YYYY-MM")
        if (!availableDaysByMonth[monthKey]) {
          availableDaysByMonth[monthKey] = []
        }

        for (let day = startDay; day <= endDay; day++) {
          availableDaysByMonth[monthKey].push(day)
        }
      })

      this.availableDaysByMonth = availableDaysByMonth
    },
    populateAvailableExNvrHoursForDay(day: string, timezone: string) {
      const momentTz = useNuxtApp().nuxt2Context.$moment.tz
      const selectedDay = useNuxtApp().nuxt2Context.$moment(day)
      const isHourWithinInterval = (interval, hour) => {
        const intervalStart = momentTz(interval.startDate, timezone)
        const intervalEnd = momentTz(interval.endDate, timezone)

        return (
          intervalStart.isSameOrBefore(hour) && intervalEnd.isSameOrAfter(hour)
        )
      }

      const availableHoursByDay = {}

      this.exNvrAvailableRecordingsIntervals.forEach((interval) => {
        const intervalStart = momentTz(interval.startDate, timezone)
        const intervalEnd = momentTz(interval.endDate, timezone)

        if (
          intervalEnd.isBefore(selectedDay.startOf("day")) ||
          intervalStart.isAfter(selectedDay.endOf("day"))
        ) {
          return
        }

        const hours = Array.from({ length: 24 }, (_, i) => i).filter((hour) =>
          isHourWithinInterval(
            interval,
            useNuxtApp()
              .nuxt2Context.$moment(selectedDay)
              .startOf("day")
              .set({ hour, minute: 0, second: 0, millisecond: 0 })
          )
        )

        if (hours.length > 0) {
          const dayKey = selectedDay.format("YYYY-MM-DD")
          availableHoursByDay[dayKey] = Array.from(
            new Set([...hours, ...(availableHoursByDay[dayKey] || [])])
          )
        }
      })

      this.availableHoursByDay = availableHoursByDay
    },
    async fetchXraySnapshot(timestamp: string, cameraExid: string) {
      const formattedTimestamp = moment(timestamp)
        .tz(useCameraStore().selectedCamera.timezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
      try {
        if (this.xraySnapshotRequestCancelFn) {
          this.xraySnapshotRequestCancelFn(
            "cancelled xray snapshot request due to new request"
          )
        }
        const { token, cancel } = axios.generateCancelTokenSource()
        this.xraySnapshotRequestCancelFn = cancel
        const { snapshots } = await EvercamApi.recordings.nearest(
          cameraExid,
          formattedTimestamp,
          null,
          {
            cancelToken: token,
          }
        )
        this.selectedXraySnapshot = snapshots[0]?.data
      } catch (e) {
        if (!axios.isCancel(e as AxiosError)) {
          useNuxtApp().nuxt2Context.$errorTracker.save(e)
          this.selectedXraySnapshot = "/unavailable.jpg"
        }
      }
    },
  },
})
