import { defineStore } from "pinia"

interface BimTransparencyState {
  shapes: [] | string[][]
}

export const useBimTransparencyStore = defineStore("bimTransparency", {
  state: (): BimTransparencyState => ({
    shapes: [],
  }),
})
