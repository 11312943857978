import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { CameraExid, ProjectExid } from "@evercam/shared/types"
import { useProjectStore } from "@evercam/dashboard/stores/project"
import { useNuxtApp } from "#app"

type CamerasBatteryReadings = Record<CameraExid, number>
type ProjectBatteryReadings = Record<ProjectExid, CamerasBatteryReadings>

type BatteryReadingsState = {
  batteryReadings: ProjectBatteryReadings
}

export const useBatteryReadingsStore = defineStore("batteryReadings", {
  state: (): BatteryReadingsState => ({
    batteryReadings: {},
  }),
  actions: {
    async fetchBatteryReadings(projectExid) {
      if (this.batteryReadings[projectExid]) {
        return
      }

      try {
        const batteryReadings =
          await EvercamApi.batteries.ProjectBatteryReadings(projectExid)
        this.updateBatteryReadings({ projectExid, batteryReadings })
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t("content.fetch_resource_failed", {
            resource: "battery readings",
          }) as string,
          error,
        })
      }
    },
    updateBatteryReadings({ projectExid, batteryReadings }) {
      const cameraBatteryReadings = batteryReadings?.reduce(
        (acc, { exid, voltage }) =>
          !isNaN(voltage) ? { ...acc, [exid]: voltage } : acc,
        {}
      )
      this.batteryReadings = {
        ...this.batteryReadings,
        [projectExid]: cameraBatteryReadings,
      }
    },
    getBatteryVoltage(cameraExid: CameraExid): number {
      const projectExid = useProjectStore().selectedProjectExid
      if (!projectExid) {
        return
      }

      const projectBatteryReadings = this.batteryReadings[projectExid]

      if (!projectBatteryReadings) {
        return
      }

      return projectBatteryReadings[cameraExid] / 1000
    },
  },
})
