import Vue from "vue"
import * as VueGoogleMaps from "vue2-google-maps"
import { defineNuxtPlugin } from "#app"

export default defineNuxtPlugin((app) => {
  Vue.use(VueGoogleMaps, {
    load: {
      key: `${app.nuxt2Context.$config.public.googleMapApiKey}`,
      libraries: "places,geometry",
    },
    installComponents: true,
  })
})
