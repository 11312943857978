import { defineStore } from "pinia"
import { useNuxtApp } from "#app"

interface LayoutState {
  loader: boolean
  isFullscreen: boolean
  fullscreenElement: Element
  isLeftSidebarVisible: boolean
  isLeftSidebarMinimized: boolean
  isLeftSidebarPersistent: boolean
  isLeftSidebarEnabled: boolean
  isAppBarVisible: boolean
  isAppBarEnabled: boolean
  isCameraTabsEnabled: boolean
  rightSidebarWidth: number
  isResizingRightSidebar: boolean
  isRightSidebarVisible: boolean
  isRightSidebarResizeable: boolean
  isRightSidebarDrawerButtonVisible: boolean
  rightSidebarMinWidth: number
  rightSidebarMaxWidth: number
  isEvercamWatermark: boolean
  mainContentRect: DOMRect
  footerRect: DOMRect
  isCleanMode: boolean
}

const placeholderRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
} as DOMRect

export const useLayoutStore = defineStore("layout", {
  state: (): LayoutState => ({
    loader: false,
    isFullscreen: false,
    fullscreenElement: null,
    isLeftSidebarVisible: true,
    isLeftSidebarMinimized: false,
    isLeftSidebarPersistent: true,
    isLeftSidebarEnabled: true,
    isAppBarVisible: true,
    isAppBarEnabled: true,
    isCameraTabsEnabled: true,
    rightSidebarWidth: 270,
    isResizingRightSidebar: false,
    isRightSidebarVisible: false,
    isRightSidebarResizeable: false,
    isRightSidebarDrawerButtonVisible: false,
    rightSidebarMinWidth: 270,
    rightSidebarMaxWidth: 800,
    isEvercamWatermark: true,
    mainContentRect: { ...placeholderRect } as DOMRect,
    footerRect: { ...placeholderRect } as DOMRect,
    isCleanMode: false,
  }),
  actions: {
    toggleLeftSidebar() {
      this.isLeftSidebarVisible = !this.isLeftSidebarVisible
    },
    toggleIsLeftSidebarPersistent() {
      this.isLeftSidebarPersistent = !this.isLeftSidebarPersistent
    },
    toggleIsLeftSidebarMinimized() {
      this.isLeftSidebarMinimized = !this.isLeftSidebarMinimized
    },
    toggleRightSidebar() {
      this.isRightSidebarVisible = !this.isRightSidebarVisible
    },
    toggleAppBarVisibility() {
      this.isAppBarVisible = !this.isAppBarVisible
    },
    enableRightSidebar({
      isResizable = false,
      width = 270,
    }: {
      isResizable?: boolean
      width?: number
    } = {}) {
      if (
        !["xs", "sm", "md"].includes(
          useNuxtApp().vue2App.$vuetify.breakpoint.name
        )
      ) {
        this.isRightSidebarVisible = true
      }
      this.isRightSidebarDrawerButtonVisible = true
      this.rightSidebarWidth = width
      this.isRightSidebarResizeable = isResizable
    },
    disableRightSidebar() {
      this.isRightSidebarVisible = false
      this.isRightSidebarResizeable = false
      this.isRightSidebarDrawerButtonVisible = false
    },
    enableCleanMode() {
      this.isLeftSidebarEnabled = false
      this.isAppBarEnabled = false
      this.isCameraTabsEnabled = false
    },
  },
  getters: {
    isPermanentLeftSidebar() {
      return !["xs", "sm", "md"].includes(
        useNuxtApp().vue2App.$vuetify.breakpoint.name
      )
    },
  },
  syncWithUrl: [
    "isLeftSidebarEnabled",
    "isAppBarEnabled",
    "isCameraTabsEnabled",
    "isCleanMode",
  ],
})
