import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import moment from "moment-timezone"
import axios from "@evercam/shared/api/client/axios"
import { AxiosError } from "axios"
import { useNuxtApp } from "#app"

type CompareState = {
  beforeTimestamp: string
  afterTimestamp: string
  snapshot: { before: string; after: string }
  availableDaysByMonth: Record<string, number[]>
  availableHoursByDay: Record<string, number[]>
  isLoadingHours: boolean
}

export const useCompareStore = defineStore("compare", {
  state: (): CompareState => ({
    beforeTimestamp: null,
    afterTimestamp: null,
    snapshot: { before: null, after: null },
    availableDaysByMonth: {},
    availableHoursByDay: {},
    isLoadingHours: false,
  }),
  actions: {
    async fetchAvailableDaysForMonth(date: string, cameraExid: string) {
      const m = moment(date)
      const year = m.format("YYYY")
      const month = m.format("MM")
      const formattedMonth = `${year}-${month}`
      if (this.availableDaysByMonth[formattedMonth]) {
        return
      }
      try {
        const { days } = await EvercamApi.recordings.availableDays({
          cameraId: cameraExid,
          year,
          month,
        })
        this.availableDaysByMonth = {
          ...this.availableDaysByMonth,
          [formattedMonth]: days,
        }
      } catch (err) {
        useNuxtApp().nuxt2Context.$errorTracker.save(err)
      }
    },
    async fetchAvailableHoursForDay(date: string, cameraExid: string) {
      const m = moment(date)
      const year = m.format("YYYY")
      const month = m.format("MM")
      const day = m.format("DD")
      const formattedDate = `${year}-${month}-${day}`

      if (this.availableHoursByDay[formattedDate]) {
        axios.cancelRequests()
        this.isLoadingHours = false

        return
      }

      // Cancel the previous requests
      if (this.isLoadingHours) {
        axios.cancelRequests()
      }

      this.isLoadingHours = true
      let isCancelled = false

      try {
        // Add cancel tokens for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)
        const { hours } = await EvercamApi.recordings.availableHours({
          cameraId: cameraExid,
          year,
          month,
          day,
          cancelToken: cancelToken.token,
        })
        this.availableHoursByDay = {
          ...this.availableHoursByDay,
          [formattedDate]: hours,
        }
      } catch (err) {
        const error = err as AxiosError
        useNuxtApp().nuxt2Context.$errorTracker.save(error)
        isCancelled = axios.isCancel(error)
      } finally {
        if (!isCancelled) {
          this.isLoadingHours = false
        }
      }
    },
  },
})
