export const strict = false
import { defineStore } from "pinia"

interface UploadStat {
  percentage: number
  totalSize: number
  uploadedSize: number
  isUploading: boolean
  isProcessing: boolean
  uploadFinished: boolean
  uploadType: string
}

interface IngestFileUploadStore {
  files: File[]
  selectedFileType: string | undefined
  uploadStats: Array<UploadStat>
  currentUploadStats: UploadStat
}

export const useIngestFileUploaderStore = defineStore("ingestFileUploader", {
  state: (): IngestFileUploadStore => ({
    files: [],
    selectedFileType: undefined,
    uploadStats: [],
    currentUploadStats: undefined,
  }),
})
