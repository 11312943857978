import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import {
  Snapshot,
  AvailableDaysResponsePayload,
  AvailableHoursResponsePayload,
} from "@evercam/shared/types/recording"

interface SnapshotState {
  availableDays: AvailableDaysResponsePayload | null
  availableHours: AvailableHoursResponsePayload | null
  latestSnapshot: Snapshot
  oldestSnapshot: Snapshot
  cameraExid: string | null
  maxDate: string | null
}

export const useSnapshotStore = defineStore("snapshot", {
  state: (): SnapshotState => ({
    latestSnapshot: {
      data: null,
      createdAt: null,
    },
    oldestSnapshot: {
      data: null,
      createdAt: null,
    },
    availableDays: null,
    availableHours: null,
    cameraExid: null,
    maxDate: null,
  }),
  getters: {
    oldestSnapshotTimestamp: (state) => state.oldestSnapshot?.createdAt,
    latestSnapshotTimestamp: (state) => state.latestSnapshot?.createdAt,
  },
  actions: {
    reset() {
      this.latestSnapshot = {
        data: null,
        createdAt: null,
      }
      this.oldestSnapshot = {
        data: null,
        createdAt: null,
      }
      this.maxDate = null
    },

    async updateLatestAndOldestSnapshots(cameraExid: string) {
      this.cameraExid = cameraExid

      await Promise.all([
        this.updateLatestSnapshot(cameraExid),
        this.updateOldestSnapshot(cameraExid),
      ])
    },
    async updateLatestSnapshot(cameraExid: string) {
      this.cameraExid = cameraExid
      let snapshot: Snapshot

      try {
        if (this.maxDate) {
          const { snapshots } = await EvercamApi.recordings.nearest(
            cameraExid,
            this.maxDate
          )
          snapshot = snapshots[0]
        } else {
          snapshot = await EvercamApi.recordings.latest(cameraExid)
        }
      } catch (e) {
        console.log(e)
      }
      this.latestSnapshot = {
        data: snapshot?.data || "unavailable.jpg",
        createdAt: snapshot?.createdAt || new Date().toISOString(),
      }
    },
    async updateOldestSnapshot(cameraExid: string) {
      this.cameraExid = cameraExid
      let response: Snapshot

      try {
        response = await EvercamApi.recordings.oldest(cameraExid)
      } catch (e) {
        console.log(e)
      }

      this.oldestSnapshot = {
        data: response?.data || "unavailable.jpg",
        createdAt: response?.createdAt || new Date().toISOString(),
      }
    },
  },
})
