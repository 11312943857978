import { defineStore } from "pinia"
import { camelizeKeys } from "humps"
import moment from "moment-timezone"
import { AnalyticsEvent, WeatherResponsePayload } from "@evercam/shared/types"
import { Timestamp } from "@evercam/shared/types"
import { WeatherProvider } from "@evercam/shared/types"
import { WeatherApi } from "@evercam/shared/api/weatherApi"
import { useNuxtApp } from "#app"

interface WeatherState {
  dialog: boolean
  isLoading: boolean
  weather: WeatherResponsePayload | null
  targetTimestamp: Timestamp
  currentStartTime: number | null
  isDifferentDay: boolean
  selectedWeatherProvider: WeatherProvider
}
export const useWeatherStore = defineStore({
  id: "weather",
  state: (): WeatherState => ({
    dialog: false,
    isLoading: false,
    weather: null,
    targetTimestamp: undefined,
    currentStartTime: null,
    isDifferentDay: true,
    selectedWeatherProvider: WeatherProvider.WeatherStack,
  }),
  actions: {
    async fetchWeatherData(timezone: string, exid: string) {
      // @ts-ignore
      if (!useNuxtApp().nuxt2Context.$permissions.project.has.weatherBYOD()) {
        this.selectedWeatherProvider = WeatherProvider.WeatherStack
      }

      let params
      let provider
      let notCurrentDay = !!moment().diff(moment(this.targetTimestamp), "days")
      if (this.targetTimestamp && notCurrentDay) {
        provider = WeatherApi.historical.getWeatherBeforeDate
        const startOfDay = moment(this.targetTimestamp).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        const startTime = startOfDay.unix()

        this.isDifferentDay = startTime !== moment(this.currentStartTime).unix()
        this.currentStartTime = startTime

        params = {
          day: moment(this.targetTimestamp)
            .tz(timezone || "Europe/Dublin")
            .format("YYYY-MM-DD"),
        }
      } else {
        this.currentStartTime = null
        provider = WeatherApi.current.getCurrentWeather
      }

      let weather
      request: try {
        this.isLoading = true
        if (this.targetTimestamp && !this.isDifferentDay) {
          weather = this.weather
          break request
        }
        weather = await provider({
          projectExid: exid,
          weatherProvider: this.selectedWeatherProvider,
          payload: params,
        })
      } catch (e) {
        useNuxtApp().nuxt2Context.$errorTracker.save(e, "weather", {
          projectExid: exid,
          weatherProvider: this.selectedWeatherProvider,
          payload: params,
        })
      }
      if (this.targetTimestamp && weather?.length) {
        let targetTimestamp = ""
        if (this.selectedWeatherProvider === WeatherProvider.AerisWeather) {
          targetTimestamp = moment(this.targetTimestamp)
            .tz(timezone || "Europe/Dublin")
            .minutes(0)
            .seconds(0)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss")
        } else {
          targetTimestamp =
            moment(this.targetTimestamp)
              .tz(timezone || "Europe/Dublin")
              .minutes(0)
              .seconds(0)
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss") + "Z"
        }
        weather.forEach((entry, _) => {
          const isTargetHour = entry.hour === targetTimestamp
          entry.isRequestedWeather = isTargetHour
        })
      }
      let weatherData = useNuxtApp().nuxt2Context.$device.isMobile
        ? weather?.filter((_, index) => index % 2 === 0)
        : weather
      this.weather = camelizeKeys(weatherData)
      this.isLoading = false
    },
    changeWeatherVisibility(visibilityStatus) {
      if (this.dialog === visibilityStatus) {
        return
      }
      this.dialog = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(AnalyticsEvent.Weather, {
        visible: visibilityStatus,
      })
    },
  },
})
