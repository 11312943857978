import posthog from "posthog-js"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { defineNuxtPlugin } from "#app"
export interface UserParams {
  firstname?: string
  lastname?: string
  email: string
  telephone?: string
  country?: string
  isAdmin?: boolean
  persona?: string
  companyExid: string
  companyName: string
  isImpersonationLogin: boolean
  ImpersonatedUserEmail?: string
  ImpersonatedUserFirstname?: string
  impersonatedUserLastname?: string
}
export default defineNuxtPlugin(async (app) => {
  const { $config, isDev } = app.nuxt2Context
  const accountStore = useAccountStore()
  const identify = (
    params: UserParams = getUserIdentificationParams()
  ): void => {
    if (params?.email) {
      posthog.group("company", params.companyExid, {
        name: params.companyName,
      })
      posthog.identify(params.email, params)
      posthog.startSessionRecording()
    }
    posthog.opt_in_capturing()
  }

  const reset = (): void => {
    posthog.reset()
  }

  const capture = (
    eventName: string,
    eventData: Record<string, any> = {}
  ): void => {
    const cameraStore = useCameraStore()
    const projectStore = useProjectStore()

    posthog.capture(eventName, {
      ...eventData,
      cameraExid: cameraStore.selectedCameraExid,
      cameraName: cameraStore.selectedCamera?.name,
      projectExid: projectStore.selectedProjectExid,
      projectName: projectStore.selectedProjectName,
      companyExid: accountStore.companyExid,
      companyName: accountStore.companyName,
    })
  }

  const getUserIdentificationParams = (): UserParams => {
    const {
      firstname,
      lastname,
      email,
      telephone,
      country,
      isAdmin,
      persona,
      companyExid,
      companyName,
      isImpersonationLogin,
      impersonatorEmail,
    } = accountStore || {}

    if (isImpersonationLogin) {
      return {
        isImpersonationLogin,
        ImpersonatedUserFirstname: firstname,
        impersonatedUserLastname: lastname,
        ImpersonatedUserEmail: email,
        email: impersonatorEmail,
        companyExid: "evercam.io",
        companyName: "Evercam",
      }
    } else {
      return {
        firstname,
        lastname,
        email: email,
        telephone,
        country,
        isAdmin,
        persona,
        companyExid: companyExid,
        companyName: companyName,
        isImpersonationLogin,
      }
    }
  }

  const isBlacklisted = /bchpolice.sharepoint.com/.test(document.referrer)

  if (isDev || isBlacklisted || !$config?.public.analyticsEnabled) {
    app.provide("posthog", {
      identify: () => {},
      reset: () => {},
      capture: () => {},
    })

    return
  }
  posthog.init($config?.public.posthogPublicApiKey, {
    api_host: "https://app.posthog.com",
    disable_session_recording: true,
    loaded: () => identify(),
  })

  app.provide("posthog", {
    identify,
    reset,
    capture,
    startSessionRecording: posthog.startSessionRecording.bind(posthog),
    stopSessionRecording: posthog.stopSessionRecording.bind(posthog),
  })
})
