import { inactivityListener } from "@evercam/ui"

import Vue from "vue"

if (!Vue.__inactivityListener_mixin__) {
  // See https://nuxtjs.org/docs/directory-structure/plugins/#global-mixins
  Vue.__inactivityListener_mixin__ = true

  Vue.mixin(inactivityListener)
}
