import { defineStore } from "pinia"
import { AnalyticsEvent, CopilotLayout } from "@evercam/shared/types"
import { useNuxtApp } from "#app"

interface CopilotState {
  isActive: boolean
  isCollapsed: boolean
  layout: CopilotLayout
}

export const useCopilotStore = defineStore("copilot", {
  state: (): CopilotState => ({
    isActive: false,
    isCollapsed: false,
    layout: CopilotLayout.Floating,
  }),
  actions: {
    toggleIsCollapsed() {
      this.isCollapsed = !this.isCollapsed
    },
    toggleCopilot() {
      this.isActive = !this.isActive

      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.CopilotToggle,
        {
          value: this.isActive,
        }
      )
    },
  },
  getters: {
    isFloating(): boolean {
      return this.layout === CopilotLayout.Floating
    },
  },
})
