import Vue from "vue"
import { defineNuxtPlugin } from "#app"

const refs = {}

Vue.directive("global-ref", {
  bind: (element, { value }) => {
    refs[value] = element
  },
})

export default defineNuxtPlugin((app) => {
  app.provide("globalRefs", refs)
})
