import { defineStore } from "pinia"
import { Media } from "@evercam/shared/types/media"

interface MediaHubState {
  mediaItems: Media[]
  filterParamsStringify: string | null
  totalMediaItems: number
}

export const useMediaHubStore = defineStore("mediaHub", {
  state: (): MediaHubState => ({
    mediaItems: [],
    filterParamsStringify: null,
    totalMediaItems: 0,
  }),
  actions: {
    forceReloadMediaHub() {
      this.filterParamsStringify = null
    },
  },
})
